import type { InputHTMLAttributes } from 'react';

import { LanguageCode } from 'src/utils/lang';

import type { ComponentType } from 'src/app/Constants';
import type { SelectOptionConfig } from 'src/UI/SelectInput/types';
import type { EmptyObject, NonEmptyList, PlainObject } from 'src/app/types';
import type { SupportedLanguageCode } from 'src/utils/lang';
import type { Tier1ValidationRule } from 'src/components/Tier1';
import type { Tier2ValidationRule } from 'src/components/Tier2';
import type { EmailValidationRule } from 'src/components/Tier2/Email';

import type { PortalContentKey } from '../content/types';

type RegExpPattern = string;
type RegExpFlags = string;
/**
 * A structure to define RegExp patterns in the Portal Config
 *
 * It is a tuple where the first index is a string RegExp pattern and the optional second arg is any RegExp flags. This
 * structure should be passed to the `RegExp` constructor directly (e.g. `new RegExp(tuple[0], tuple[1])`). Any special
 * RegExp characters that should be matched literally must be escaped in the pattern string.
 */
export type PortalConfigRegExpSetting = [RegExpPattern, RegExpFlags?];

// Sub-config objects
type DisabledUiSettings = {
    registerFlow?: boolean;
    fullstepupFlow?: boolean;
    helpModal?: boolean;
    usernameFlow?: boolean;
};

type AssistedRegistrationSettings = {
    enableSkipRegistration: 'off' | 'manual' | 'auto';
    enableTokenVerification: boolean;
};

type ClickToCallSettings = {
    isEnabled: boolean;
    callMeInMinuteOptions: SelectOptionConfig[];
    topicDiscussionOptions: SelectOptionConfig[];
};

type Tier1SmartFieldConfigEntry = {
    match: PortalConfigRegExpSetting;
    /**
     * This can be any of the normal field config settings EXCEPT `componentType`. Any setting will override the base
     * configuration when the entry is matched.
     */
    config: {
        /** The `.dataId` for the matching config will be used as the payload field name instead of the `.id` */
        dataId: string;
    } & Partial<Omit<Tier1ComponentUiIdentificationTypeField, 'componentType'>>;
};

interface BaseUiIdentificationTypeField {
    componentType: string;
    inputType: 'text' | 'email';
    id: string;
    idProcessEngine?: string;
    labelKey: PortalContentKey;
    subLabelKey?: PortalContentKey;
    label?: string;
    properties: InputHTMLAttributes<HTMLInputElement>;
}

interface Tier1ComponentUiIdentificationTypeField extends BaseUiIdentificationTypeField {
    componentType: typeof ComponentType.TIER_1;
    inputType: 'text';
    validationRules: Tier1ValidationRule[];
    /**
     * Smart Field configuration
     *
     * The `.oneOf` configuration signals a Smart Field. A Smart Field is able to support multiple configurations for a
     * field. This includes different validation rules and any other usual Tier1 field settings. The entry's `.match`
     * that matches the field value has its `.config` merged onto the other field config settings. All top-level fields
     * are overridden except `.properties` which is merged together.
     *
     * The top-level `.id` is no longer used for the payload field name. Instead the `.config.dataId` is used in place
     * when the Big 5 data is submitted.
     *
     * When a Smart Field is used, the base top-level settings should enable just enough configuration to validate the
     * field until the user enters a value that matches one of the Smart configurations. The field is only valid if it
     * matches one of the Smart Field configurations.
     */
    oneOf?: Tier1SmartFieldConfigEntry[];
}

// Remove `export` once Portal Config Service bug that removes empty string keys is fixed
export interface Tier2ComponentUiIdentificationTypeField extends BaseUiIdentificationTypeField {
    componentType: typeof ComponentType.TIER_2;
    inputType: 'text';
    validationRules: Tier2ValidationRule[];
    mask: string;
    // NOTE In Portal Config Service, if a field is set to empty string, it will not be sent in the
    // payload. This applies to `maskChar` for Zip Code fields. Once the service bug is fixed,
    // remove the optional annotation.
    maskChar?: string;
    maskPlaceholder: string;
}

interface EmailComponentUiIdentificationTypeField extends BaseUiIdentificationTypeField {
    componentType: typeof ComponentType.EMAIL;
    inputType: 'email';
    validationRules: EmailValidationRule[];
}

type UiIdentificationTypeField =
    | Tier1ComponentUiIdentificationTypeField
    | Tier2ComponentUiIdentificationTypeField
    | EmailComponentUiIdentificationTypeField;

type UiIdentificationTypeGroup = {
    id: string;
    aliasIds?: string[];
    labelKey: PortalContentKey;
    label: string;
    contentKey?: string;
    sampleCards?: number[];
    elements: UiIdentificationTypeField[];
};

type UserAccountLevels = 'BASIC' | 'NONMEMBER' | 'MEMBER';

type UserAccessGroup = {
    userTypes: UserAccountLevels[];
    tfaMinLevel: UserAccountLevels;
};

type QualtricsConfig = {
    isEnabled?: boolean;
    qualtricsId?: string;
};

/** PUBLIC API */
type PortalConfigDataConstructor = {
    (rawData: PlainObject): PortalConfigData;
};

export type PortalConfigData = {
    portalName: string;
    portalDisplayName: string;
    hsidDomainUrl: string;
    tmxDomainUrl: string;
    brandUrl: string;
    // NOTE contentHost is dynamically added by the HSID11 server
    contentHost: string;
    theme: 'uhc' | 'optum' | 'hsid';
    uiIdentificationTypes: UiIdentificationTypeGroup[];
    userAccess: UserAccessGroup | EmptyObject;
    supportedLocales: NonEmptyList<SupportedLanguageCode>;
    localeSpecific: {
        // Required URLs
        targetUrl: string;
        signoutUrl: string;
        siteUrl: string;
        // Optional URLs
        registerUrl?: string;
        settingsBackUrl?: string;
        skipUrl?: string;
        // Legal URLs
        privacyPolicyUrl?: string;
        termsOfUseUrl?: string;
    };
    feature: {
        assistedRegistration: AssistedRegistrationSettings | EmptyObject;
        clickToCall: ClickToCallSettings | EmptyObject;
        disabledUi: DisabledUiSettings | EmptyObject;
        eligibility: 'P' | 'F';
        enableMultiTierRegistrationFlow: boolean;
        /**
         * Additional data to read in from URL params and send with submitted form
         *
         * An empty object means no additional data from the URL. Top level keys match `FlowNames` (e.g. "register,"
         * "login," etc) and are set as objects where the keys are the URL params to read in and the values are the keys
         * to use to send the data to the backend. For the following configuration:
         *
         * <code>
         * {
         *   register: { inKey: 'outKey' },
         * }
         * </code>
         *
         * When Registration Step 1 loads, it will look for the `inKey` URL param and consume it from the URL. When the
         * Step 1 form is submitted, the value from `inKey` in the URL will be added to the sent JSON as `outKey`.
         *
         * @note Only `FlowNames.REGISTER` is supported currently
         */
        extraFlowDataFromUrl:
            | EmptyObject
            | {
                  register?: Record<string, string>;
              };
        // US5884700, Persist URL data through HSID experience
        persistUrlParamsToPortal: string[];
        isAikyamMFAEnabled: boolean;
        isAikyamLoginEnabled: boolean;
        isCoppaEnabled: boolean;
        isNativeApp: boolean;
        isOBAEnabled: boolean;
        shouldCallLegacyPreAuth: boolean;
        isAccountRecoveryFlowEnabled: boolean;
        /**
         * Enable a Web Chat experience for the portal
         *
         * Possible values are:
         * - "" (empty string) - DEFAULT Do not enable a web chat experience for the portal
         * - "caip" - Enable the CAiP Bot-based chat experience for the portal
         * - "live" - Enable the LivePerson chat experience for the portal
         * - false - DEPRECATED Do not enable a web chat experience for the portal (same as "")
         * - true - DEPRECATED Enable the CAiP Bot-based chat experience for the portal (same as "caip")
         */
        isWebChatEnabled: boolean | 'caip' | 'live' | '';
        qualtrics: QualtricsConfig | EmptyObject;
        shouldHideHsidBrandLogo: boolean;
        shouldUseHsidEternal: boolean;
        isRBAReassessmentEnabled: boolean;
        isPortalDecommissionedOnLegacy: boolean;
    };
};

const ensureIdentificationTypesDefaults = (
    identificationTypes: UiIdentificationTypeGroup[]
): UiIdentificationTypeGroup[] =>
    identificationTypes.map(({ elements, ...idType }) => ({
        ...idType,
        elements: elements.map(element => {
            if (element.componentType === 'tier2') {
                const { maskChar } = element;
                // Fill in missing `maskChar: ''` for `componentType: 'tier2'`
                return {
                    ...element,
                    ...{ maskChar: typeof maskChar !== 'undefined' ? maskChar : '' },
                };
            }

            return element;
        }),
    }));

/**
 * @note sort keys alpha
 */
const PortalConfigFeatures = ({
    assistedRegistration = {},
    clickToCall = {},
    disabledUi = {},
    eligibility = 'P',
    enableMultiTierRegistrationFlow = false,
    extraFlowDataFromUrl = {},
    persistUrlParamsToPortal = [],
    isAikyamMFAEnabled = false,
    isAikyamLoginEnabled = false,
    isCoppaEnabled = false,
    shouldCallLegacyPreAuth = false,
    isAccountRecoveryFlowEnabled = false,
    isNativeApp = false,
    isOBAEnabled = false,
    isWebChatEnabled = false,
    qualtrics = {},
    shouldHideHsidBrandLogo = false,
    shouldUseHsidEternal = false,
    isRBAReassessmentEnabled = false,
    isPortalDecommissionedOnLegacy = false,
}: PlainObject = {}): PortalConfigData['feature'] => ({
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    assistedRegistration,
    clickToCall,
    disabledUi,
    eligibility,
    enableMultiTierRegistrationFlow,
    extraFlowDataFromUrl,
    persistUrlParamsToPortal,
    isAikyamMFAEnabled,
    isAikyamLoginEnabled,
    isCoppaEnabled,
    shouldCallLegacyPreAuth,
    isAccountRecoveryFlowEnabled,
    isNativeApp,
    isOBAEnabled,
    isWebChatEnabled,
    qualtrics,
    shouldHideHsidBrandLogo,
    shouldUseHsidEternal,
    isRBAReassessmentEnabled,
    isPortalDecommissionedOnLegacy,
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */
});

const PortalConfigLocalSpecific = ({
    targetUrl,
    siteUrl,
    signoutUrl,
    skipUrl,
    settingsBackUrl,
    registerUrl,
    privacyPolicyUrl,
    termsOfUseUrl,
}: PlainObject = {}): PortalConfigData['localeSpecific'] => ({
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    targetUrl,
    siteUrl,
    signoutUrl,
    ...(registerUrl ? { registerUrl } : null),
    ...(settingsBackUrl ? { settingsBackUrl } : null),
    ...(skipUrl ? { skipUrl } : null),
    ...(privacyPolicyUrl ? { privacyPolicyUrl } : null),
    ...(termsOfUseUrl ? { termsOfUseUrl } : null),
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */
});

/**
 * Create a complete PortalConfigData object (with default values for all optional settings) from a raw data object,
 * usually from network or localStorage
 *
 * Any case where you need to generate a complete PortalConfigData object, this constructor should be used. All selector
 * functions that operate on PortalConfigData should be able to assume most settings will have a value, even a default
 * value.
 *
 * @param rawData Raw data (usually from network or localStorage) to populate a PortalConfigData object
 * @returns A complete PortalConfigData object with all optional values set to defaults
 *
 * @todo Add data validation as `PortalConfigData.assert()` and `PortalConfigData.validate()`
 */
export const PortalConfigData: PortalConfigDataConstructor = ({
    portalName,
    portalDisplayName,
    hsidDomainUrl,
    tmxDomainUrl,
    brandUrl,
    contentHost,
    theme = 'uhc',
    uiIdentificationTypes = [],
    userAccess = {},
    supportedLocales = [LanguageCode.EN],
    feature = {},
    localeSpecific = {},
}) => ({
    // NOTE unable to get types to work without complaint due to `any` types. Disabling linting to unblock work.
    // TODO Fix types
    /* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument */
    portalName,
    portalDisplayName,
    hsidDomainUrl,
    tmxDomainUrl,
    brandUrl,
    contentHost,
    theme,
    uiIdentificationTypes: ensureIdentificationTypesDefaults(uiIdentificationTypes),
    userAccess,
    supportedLocales,
    localeSpecific: PortalConfigLocalSpecific(localeSpecific),
    feature: PortalConfigFeatures(feature),
    /* eslint-enable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument */
});
