export interface Country {
    label: string;
    name: string;
    countryCode: string;
    isoCountryCode: string;
}

export const usOnlyCountryList: Country[] = [
    {
        label: 'unitedstates',
        name: 'United States',
        countryCode: '1',
        isoCountryCode: 'US',
    },
];

export const countryList: Country[] = [
    {
        label: 'unitedstates',
        name: 'United States',
        countryCode: '1',
        isoCountryCode: 'US',
    },
    {
        label: 'afghanistan',
        name: 'Afghanistan',
        countryCode: '93',
        isoCountryCode: 'AF',
    },
    {
        label: 'albania',
        name: 'Albania',
        countryCode: '355',
        isoCountryCode: 'AL',
    },
    {
        label: 'algeria',
        name: 'Algeria',
        countryCode: '213',
        isoCountryCode: 'DZ',
    },
    {
        label: 'anguilla',
        name: 'Anguilla',
        countryCode: '1',
        isoCountryCode: 'AI',
    },
    {
        label: 'antiguaandbarbuda',
        name: 'Antigua and Barbuda',
        countryCode: '1',
        isoCountryCode: 'AG',
    },
    {
        label: 'argentina',
        name: 'Argentina',
        countryCode: '54',
        isoCountryCode: 'AR',
    },
    {
        label: 'armenia',
        name: 'Armenia',
        countryCode: '374',
        isoCountryCode: 'AM',
    },
    {
        label: 'aruba',
        name: 'Aruba',
        countryCode: '297',
        isoCountryCode: 'AW',
    },
    {
        label: 'australia',
        name: 'Australia',
        countryCode: '61',
        isoCountryCode: 'AU',
    },
    {
        label: 'austria',
        name: 'Austria',
        countryCode: '43',
        isoCountryCode: 'AT',
    },
    {
        label: 'bahamas',
        name: 'Bahamas',
        countryCode: '1',
        isoCountryCode: 'BS',
    },
    {
        label: 'bahrain',
        name: 'Bahrain',
        countryCode: '973',
        isoCountryCode: 'BH',
    },
    {
        label: 'bangladesh',
        name: 'Bangladesh',
        countryCode: '880',
        isoCountryCode: 'BD',
    },
    {
        label: 'barbados',
        name: 'Barbados',
        countryCode: '1',
        isoCountryCode: 'BB',
    },
    {
        label: 'belgium',
        name: 'Belgium',
        countryCode: '32',
        isoCountryCode: 'BE',
    },
    {
        label: 'belize',
        name: 'Belize',
        countryCode: '501',
        isoCountryCode: 'BZ',
    },
    {
        label: 'bermuda',
        name: 'Bermuda',
        countryCode: '1',
        isoCountryCode: 'BM',
    },
    {
        label: 'bhutan',
        name: 'Bhutan',
        countryCode: '975',
        isoCountryCode: 'BT',
    },
    {
        label: 'bolivia',
        name: 'Bolivia',
        countryCode: '591',
        isoCountryCode: 'BO',
    },
    {
        label: 'bosniaandherzegovina',
        name: 'Bosnia and Herzegovina',
        countryCode: '387',
        isoCountryCode: 'BA',
    },
    {
        label: 'botswana',
        name: 'Botswana',
        countryCode: '267',
        isoCountryCode: 'BW',
    },
    {
        label: 'brazil',
        name: 'Brazil',
        countryCode: '55',
        isoCountryCode: 'BR',
    },
    {
        label: 'bulgaria',
        name: 'Bulgaria',
        countryCode: '359',
        isoCountryCode: 'BG',
    },
    {
        label: 'burkinafaso',
        name: 'Burkina Faso',
        countryCode: '226',
        isoCountryCode: 'BF',
    },
    {
        label: 'burundi',
        name: 'Burundi',
        countryCode: '257',
        isoCountryCode: 'BI',
    },
    {
        label: 'cambodia',
        name: 'Cambodia',
        countryCode: '855',
        isoCountryCode: 'KH',
    },
    {
        label: 'cameroon',
        name: 'Cameroon',
        countryCode: '237',
        isoCountryCode: 'CM',
    },
    {
        label: 'canada',
        name: 'Canada',
        countryCode: '1',
        isoCountryCode: 'CA',
    },
    {
        label: 'capeverde',
        name: 'Cape Verde',
        countryCode: '238',
        isoCountryCode: 'CV',
    },
    {
        label: 'caymanislands',
        name: 'Cayman Islands',
        countryCode: '1',
        isoCountryCode: 'KY',
    },
    {
        label: 'centralafricanrepublic',
        name: 'Central African Republic',
        countryCode: '236',
        isoCountryCode: 'CF',
    },
    {
        label: 'chad',
        name: 'Chad',
        countryCode: '235',
        isoCountryCode: 'TD',
    },
    {
        label: 'chile',
        name: 'Chile',
        countryCode: '56',
        isoCountryCode: 'CL',
    },
    {
        label: 'china',
        name: 'China',
        countryCode: '86',
        isoCountryCode: 'CN',
    },
    {
        label: 'colombia',
        name: 'Colombia',
        countryCode: '57',
        isoCountryCode: 'CO',
    },
    {
        label: 'comoros',
        name: 'Comoros',
        countryCode: '269',
        isoCountryCode: 'KM',
    },
    {
        label: 'costarica',
        name: 'Costa Rica',
        countryCode: '506',
        isoCountryCode: 'CR',
    },
    {
        label: 'croatia',
        name: 'Croatia',
        countryCode: '385',
        isoCountryCode: 'HR',
    },
    {
        label: 'cyprus',
        name: 'Cyprus',
        countryCode: '357',
        isoCountryCode: 'CY',
    },
    {
        label: 'czechrepublic',
        name: 'Czech Republic',
        countryCode: '420',
        isoCountryCode: 'CZ',
    },
    {
        label: 'denmark',
        name: 'Denmark',
        countryCode: '45',
        isoCountryCode: 'DK',
    },
    {
        label: 'democraticrepublicofthecongo',
        name: 'Democratic Republic of the Congo',
        countryCode: '243',
        isoCountryCode: 'CD',
    },
    {
        label: 'djibouti',
        name: 'Djibouti',
        countryCode: '253',
        isoCountryCode: 'DJ',
    },
    {
        label: 'dominica',
        name: 'Dominica',
        countryCode: '1',
        isoCountryCode: 'DM',
    },
    {
        label: 'dominicanrepublic',
        name: 'Dominican Republic',
        countryCode: '1',
        isoCountryCode: 'DO',
    },
    {
        label: 'ecuador',
        name: 'Ecuador',
        countryCode: '593',
        isoCountryCode: 'EC',
    },
    {
        label: 'egypt',
        name: 'Egypt',
        countryCode: '20',
        isoCountryCode: 'EG',
    },
    {
        label: 'elsalvador',
        name: 'El Salvador',
        countryCode: '503',
        isoCountryCode: 'SV',
    },
    {
        label: 'estonia',
        name: 'Estonia',
        countryCode: '372',
        isoCountryCode: 'EE',
    },
    {
        label: 'ethiopia',
        name: 'Ethiopia',
        countryCode: '251',
        isoCountryCode: 'ET',
    },
    {
        label: 'fiji',
        name: 'Fiji',
        countryCode: '679',
        isoCountryCode: 'FJ',
    },
    {
        label: 'finland',
        name: 'Finland',
        countryCode: '358',
        isoCountryCode: 'FI',
    },
    {
        label: 'france',
        name: 'France',
        countryCode: '33',
        isoCountryCode: 'FR',
    },
    {
        label: 'gabon',
        name: 'Gabon',
        countryCode: '241',
        isoCountryCode: 'GA',
    },
    {
        label: 'gambia',
        name: 'Gambia',
        countryCode: '220',
        isoCountryCode: 'GM',
    },
    {
        label: 'georgia',
        name: 'Georgia',
        countryCode: '995',
        isoCountryCode: 'GE',
    },
    {
        label: 'germany',
        name: 'Germany',
        countryCode: '49',
        isoCountryCode: 'DE',
    },
    {
        label: 'ghana',
        name: 'Ghana',
        countryCode: '233',
        isoCountryCode: 'GH',
    },
    {
        label: 'greece',
        name: 'Greece',
        countryCode: '30',
        isoCountryCode: 'GR',
    },
    {
        label: 'greenland',
        name: 'Greenland',
        countryCode: '299',
        isoCountryCode: 'GL',
    },
    {
        label: 'guam',
        name: 'Guam',
        countryCode: '1',
        isoCountryCode: 'GU',
    },
    {
        label: 'guatemala',
        name: 'Guatemala',
        countryCode: '502',
        isoCountryCode: 'GT',
    },
    {
        label: 'guineabissau',
        name: 'Guinea-Bissau',
        countryCode: '245',
        isoCountryCode: 'GW',
    },
    {
        label: 'guyana',
        name: 'Guyana',
        countryCode: '592',
        isoCountryCode: 'GY',
    },
    {
        label: 'haiti',
        name: 'Haiti',
        countryCode: '509',
        isoCountryCode: 'HT',
    },
    {
        label: 'honduras',
        name: 'Honduras',
        countryCode: '504',
        isoCountryCode: 'HN',
    },
    {
        label: 'hongkong',
        name: 'Hong Kong',
        countryCode: '852',
        isoCountryCode: 'HK',
    },
    {
        label: 'hungary',
        name: 'Hungary',
        countryCode: '36',
        isoCountryCode: 'HU',
    },
    {
        label: 'india',
        name: 'India',
        countryCode: '91',
        isoCountryCode: 'IN',
    },
    {
        label: 'indonesia',
        name: 'Indonesia',
        countryCode: '62',
        isoCountryCode: 'ID',
    },
    {
        label: 'iraq',
        name: 'Iraq',
        countryCode: '964',
        isoCountryCode: 'IQ',
    },
    {
        label: 'ireland',
        name: 'Ireland',
        countryCode: '353',
        isoCountryCode: 'IE',
    },
    {
        label: 'israel',
        name: 'Israel',
        countryCode: '972',
        isoCountryCode: 'IL',
    },
    {
        label: 'italy',
        name: 'Italy',
        countryCode: '39',
        isoCountryCode: 'IT',
    },
    {
        label: 'ivorycoast',
        name: "Ivory Coast (Cote D'ivoire)",
        countryCode: '225',
        isoCountryCode: 'CI',
    },
    {
        label: 'jamaica',
        name: 'Jamaica',
        countryCode: '1',
        isoCountryCode: 'JM',
    },
    {
        label: 'japan',
        name: 'Japan',
        countryCode: '81',
        isoCountryCode: 'JP',
    },
    {
        label: 'jordan',
        name: 'Jordan',
        countryCode: '962',
        isoCountryCode: 'JO',
    },
    {
        label: 'kazakhstan',
        name: 'Kazakhstan',
        countryCode: '7',
        isoCountryCode: 'KZ',
    },
    {
        label: 'kenya',
        name: 'Kenya',
        countryCode: '254',
        isoCountryCode: 'KE',
    },
    {
        label: 'kosovo',
        name: 'Kosovo',
        countryCode: '383',
        isoCountryCode: 'XK',
    },
    {
        label: 'kuwait',
        name: 'Kuwait',
        countryCode: '965',
        isoCountryCode: 'KW',
    },
    {
        label: 'kyrgyzstan',
        name: 'Kyrgyzstan',
        countryCode: '996',
        isoCountryCode: 'KG',
    },
    {
        label: 'laos',
        name: 'Laos',
        countryCode: '856',
        isoCountryCode: 'LA',
    },
    {
        label: 'latvia',
        name: 'Latvia',
        countryCode: '371',
        isoCountryCode: 'LV',
    },
    {
        label: 'lebanon',
        name: 'Lebanon',
        countryCode: '961',
        isoCountryCode: 'LB',
    },
    {
        label: 'lesotho',
        name: 'Lesotho',
        countryCode: '266',
        isoCountryCode: 'LS',
    },
    {
        label: 'liberia',
        name: 'Liberia',
        countryCode: '231',
        isoCountryCode: 'LR',
    },
    {
        label: 'liechtenstein',
        name: 'Liechtenstein',
        countryCode: '423',
        isoCountryCode: 'LI',
    },
    {
        label: 'lithuania',
        name: 'Lithuania',
        countryCode: '370',
        isoCountryCode: 'LT',
    },
    {
        label: 'luxembourg',
        name: 'Luxembourg',
        countryCode: '352',
        isoCountryCode: 'LU',
    },
    {
        label: 'macau',
        name: 'Macau',
        countryCode: '853',
        isoCountryCode: 'MO',
    },
    {
        label: 'macedonia',
        name: 'Macedonia',
        countryCode: '389',
        isoCountryCode: 'MK',
    },
    {
        label: 'madagascar',
        name: 'Madagascar',
        countryCode: '261',
        isoCountryCode: 'MG',
    },
    {
        label: 'malawi',
        name: 'Malawi',
        countryCode: '265',
        isoCountryCode: 'MW',
    },
    {
        label: 'malaysia',
        name: 'Malaysia',
        countryCode: '60',
        isoCountryCode: 'MY',
    },
    {
        label: 'mali',
        name: 'Mali',
        countryCode: '223',
        isoCountryCode: 'ML',
    },
    {
        label: 'mauritania',
        name: 'Mauritania',
        countryCode: '222',
        isoCountryCode: 'MR',
    },
    {
        label: 'mexico',
        name: 'Mexico',
        countryCode: '52',
        isoCountryCode: 'MX',
    },
    {
        label: 'moldova',
        name: 'Moldova',
        countryCode: '373',
        isoCountryCode: 'MD',
    },
    {
        label: 'mongolia',
        name: 'Mongolia',
        countryCode: '976',
        isoCountryCode: 'MN',
    },
    {
        label: 'montenegro',
        name: 'Montenegro',
        countryCode: '382',
        isoCountryCode: 'ME',
    },
    {
        label: 'morocco',
        name: 'Morocco',
        countryCode: '212',
        isoCountryCode: 'MA',
    },
    {
        label: 'mozambique',
        name: 'Mozambique',
        countryCode: '258',
        isoCountryCode: 'MZ',
    },
    {
        label: 'myanmar',
        name: 'Myanmar',
        countryCode: '95',
        isoCountryCode: 'MM',
    },
    {
        label: 'namibia',
        name: 'Namibia',
        countryCode: '264',
        isoCountryCode: 'NA',
    },
    {
        label: 'nepal',
        name: 'Nepal',
        countryCode: '977',
        isoCountryCode: 'NP',
    },
    {
        label: 'netherlands',
        name: 'Netherlands',
        countryCode: '31',
        isoCountryCode: 'NL',
    },
    {
        label: 'newzealand',
        name: 'New Zealand',
        countryCode: '64',
        isoCountryCode: 'NZ',
    },
    {
        label: 'nicaragua',
        name: 'Nicaragua',
        countryCode: '505',
        isoCountryCode: 'NI',
    },
    {
        label: 'niger',
        name: 'Niger',
        countryCode: '227',
        isoCountryCode: 'NE',
    },
    {
        label: 'nigeria',
        name: 'Nigeria',
        countryCode: '234',
        isoCountryCode: 'NG',
    },
    {
        label: 'norway',
        name: 'Norway',
        countryCode: '47',
        isoCountryCode: 'NO',
    },
    {
        label: 'oman',
        name: 'Oman',
        countryCode: '968',
        isoCountryCode: 'OM',
    },
    {
        label: 'pakistan',
        name: 'Pakistan',
        countryCode: '92',
        isoCountryCode: 'PK',
    },
    {
        label: 'palestine',
        name: 'Palestine',
        countryCode: '970',
        isoCountryCode: 'PS',
    },
    {
        label: 'panama',
        name: 'Panama',
        countryCode: '507',
        isoCountryCode: 'PA',
    },
    {
        label: 'papuanewguinea',
        name: 'Papua New Guinea',
        countryCode: '675',
        isoCountryCode: 'PG',
    },
    {
        label: 'paraguay',
        name: 'Paraguay',
        countryCode: '595',
        isoCountryCode: 'PY',
    },
    {
        label: 'peru',
        name: 'Peru',
        countryCode: '51',
        isoCountryCode: 'PE',
    },
    {
        label: 'philippines',
        name: 'Philippines',
        countryCode: '63',
        isoCountryCode: 'PH',
    },
    {
        label: 'poland',
        name: 'Poland',
        countryCode: '48',
        isoCountryCode: 'PL',
    },
    {
        label: 'portugal',
        name: 'Portugal',
        countryCode: '351',
        isoCountryCode: 'PT',
    },
    {
        label: 'puertorico',
        name: 'Puerto Rico',
        countryCode: '1',
        isoCountryCode: 'PR',
    },
    {
        label: 'qatar',
        name: 'Qatar',
        countryCode: '974',
        isoCountryCode: 'QA',
    },
    {
        label: 'romania',
        name: 'Romania',
        countryCode: '40',
        isoCountryCode: 'RO',
    },
    {
        label: 'rwanda',
        name: 'Rwanda',
        countryCode: '250',
        isoCountryCode: 'RW',
    },
    {
        label: 'saintkittsandnevis',
        name: 'Saint Kitts and Nevis',
        countryCode: '1',
        isoCountryCode: 'KN',
    },
    {
        label: 'samoa',
        name: 'Samoa',
        countryCode: '685',
        isoCountryCode: 'WS',
    },
    {
        label: 'sanmarino',
        name: 'San Marino',
        countryCode: '378',
        isoCountryCode: 'SM',
    },
    {
        label: 'saudiarabia',
        name: 'Saudi Arabia',
        countryCode: '966',
        isoCountryCode: 'SA',
    },
    {
        label: 'senegal',
        name: 'Senegal',
        countryCode: '221',
        isoCountryCode: 'SN',
    },
    {
        label: 'serbia',
        name: 'Serbia',
        countryCode: '381',
        isoCountryCode: 'RS',
    },
    {
        label: 'seychelles',
        name: 'Seychelles',
        countryCode: '248',
        isoCountryCode: 'SC',
    },
    {
        label: 'sierraleone',
        name: 'Sierra Leone',
        countryCode: '232',
        isoCountryCode: 'SL',
    },
    {
        label: 'singapore',
        name: 'Singapore',
        countryCode: '65',
        isoCountryCode: 'SG',
    },
    {
        label: 'slovakia',
        name: 'Slovakia',
        countryCode: '421',
        isoCountryCode: 'SK',
    },
    {
        label: 'solomonislands',
        name: 'Solomon Islands',
        countryCode: '677',
        isoCountryCode: 'SB',
    },
    {
        label: 'somalia',
        name: 'Somalia',
        countryCode: '252',
        isoCountryCode: 'SO',
    },
    {
        label: 'southafrica',
        name: 'South Africa',
        countryCode: '27',
        isoCountryCode: 'ZA',
    },
    {
        label: 'southkorea',
        name: 'South Korea',
        countryCode: '82',
        isoCountryCode: 'KR',
    },
    {
        label: 'southsudan',
        name: 'South Sudan',
        countryCode: '211',
        isoCountryCode: 'SS',
    },
    {
        label: 'spain',
        name: 'Spain',
        countryCode: '34',
        isoCountryCode: 'ES',
    },
    {
        label: 'srilanka',
        name: 'Sri Lanka',
        countryCode: '94',
        isoCountryCode: 'LK',
    },
    {
        label: 'suriname',
        name: 'Suriname',
        countryCode: '597',
        isoCountryCode: 'SR',
    },
    {
        label: 'swaziland',
        name: 'Swaziland',
        countryCode: '268',
        isoCountryCode: 'SZ',
    },
    {
        label: 'sweden',
        name: 'Sweden',
        countryCode: '46',
        isoCountryCode: 'SE',
    },
    {
        label: 'switzerland',
        name: 'Switzerland',
        countryCode: '41',
        isoCountryCode: 'CH',
    },
    {
        label: 'taiwan',
        name: 'Taiwan',
        countryCode: '886',
        isoCountryCode: 'TW',
    },
    {
        label: 'tajikistan',
        name: 'Tajikistan',
        countryCode: '992',
        isoCountryCode: 'TJ',
    },
    {
        label: 'tanzania',
        name: 'Tanzania',
        countryCode: '255',
        isoCountryCode: 'TZ',
    },
    {
        label: 'thailand',
        name: 'Thailand',
        countryCode: '66',
        isoCountryCode: 'TH',
    },
    {
        label: 'timorleste',
        name: 'Timor-Leste',
        countryCode: '670',
        isoCountryCode: 'TL',
    },
    {
        label: 'tonga',
        name: 'Tonga',
        countryCode: '676',
        isoCountryCode: 'TO',
    },
    {
        label: 'trinidadandtobago',
        name: 'Trinidad and Tobago',
        countryCode: '1',
        isoCountryCode: 'TT',
    },
    {
        label: 'tunisia',
        name: 'Tunisia',
        countryCode: '216',
        isoCountryCode: 'TN',
    },
    {
        label: 'turkey',
        name: 'Turkey',
        countryCode: '90',
        isoCountryCode: 'TR',
    },
    {
        label: 'turkmenistan',
        name: 'Turkmenistan',
        countryCode: '993',
        isoCountryCode: 'TM',
    },
    {
        label: 'turksandcaicosislands',
        name: 'Turks and Caicos Islands',
        countryCode: '1',
        isoCountryCode: 'TC',
    },
    {
        label: 'uganda',
        name: 'Uganda',
        countryCode: '256',
        isoCountryCode: 'UG',
    },
    {
        label: 'ukraine',
        name: 'Ukraine',
        countryCode: '380',
        isoCountryCode: 'UA',
    },
    {
        label: 'unitedarabemirates',
        name: 'United Arab Emirates',
        countryCode: '971',
        isoCountryCode: 'AE',
    },
    {
        label: 'unitedkingdom',
        name: 'United Kingdom',
        countryCode: '44',
        isoCountryCode: 'GB',
    },
    {
        label: 'uruguay',
        name: 'Uruguay',
        countryCode: '598',
        isoCountryCode: 'UY',
    },
    {
        label: 'uzbekistan',
        name: 'Uzbekistan',
        countryCode: '998',
        isoCountryCode: 'UZ',
    },
    {
        label: 'venezuela',
        name: 'Venezuela',
        countryCode: '58',
        isoCountryCode: 'VE',
    },
    {
        label: 'vietnam',
        name: 'Vietnam',
        countryCode: '84',
        isoCountryCode: 'VN',
    },
    {
        label: 'virginislandsbritish',
        name: 'Virgin Islands, British',
        countryCode: '1',
        isoCountryCode: 'VG',
    },
    {
        label: 'virginislandsus',
        name: 'Virgin Islands, US',
        countryCode: '1',
        isoCountryCode: 'VI',
    },
    {
        label: 'yemen',
        name: 'Yemen',
        countryCode: '967',
        isoCountryCode: 'YE',
    },
    {
        label: 'zambia',
        name: 'Zambia',
        countryCode: '260',
        isoCountryCode: 'ZM',
    },
    {
        label: 'zimbabwe',
        name: 'Zimbabwe',
        countryCode: '263',
        isoCountryCode: 'ZW',
    },
];

export const isoCountryCodes: string[] = [
    'US',
    'AF',
    'AL',
    'DZ',
    'AD',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'BN',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CK',
    'CR',
    'HR',
    'CY',
    'CZ',
    'CD',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FO',
    'FJ',
    'FI',
    'FR',
    'GF',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'CI',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'XK',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NP',
    'NL',
    'AN',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NO',
    'OM',
    'PK',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PL',
    'PT',
    'PR',
    'QA',
    'CD',
    'RE',
    'RO',
    'RU',
    'RW',
    'KN',
    'LC',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'KR',
    'SS',
    'ES',
    'LK',
    'SR',
    'SZ',
    'SE',
    'CH',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VG',
    'VI',
    'YE',
    'ZM',
    'ZW',
];
